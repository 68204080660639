import { useSelector } from "react-redux";
import { StoreProptypes } from "../../PropTypes/StoreProptypes";
import { useTranslation } from "react-i18next";
import DashboardPagesTemplate from "../../Template/DashboardPagesTemplate";
import DashboardPageTitle from "../../Components/InnerPageTitle";
import { Alert, Button, Card, CardBody, Col, Form, Row } from "react-bootstrap";
import { useRef, useState } from "react";

import "../../Assets/scss/pages/report-problem.scss";

const problemOptions = [
    "Accessibility Profiles",
    "Accessibility Statement",
    "Background",
    "Big Black/White Cursor",
    "Content Scaling",
    "Light/High/Smart Contrast",
    "Dictionary",
    "Dyslexia Font",
    "Filter Content",
    "Font Size",
    "Hide Images",
    "Hide Interface",
    "High/Low Saturation",
    "Highlight Focus/Hover/Links/Titles",
    "Invert Colors",
    "Languages",
    "Letter Spacing",
    "Libras",
    "Line Height",
    "Monochrome",
    "Move Widget",
    "Mute Sounds",
    "Oversize Widget",
    "Read Mode",
    "Readable Font",
    "Reading Guide",
    "Reading Mask",
    "Reset Button",
    "Screen Reader",
    "Stop Animations",
    "Text Alignment",
    "Text Color",
    "Text Magnifier",
    "Title Color",
    "Virtual Keyboard",
    "Other"
]


const ReportProblemPage = () => {

    // Redux Data
    const { currentWebsite, currentUserStatus } = useSelector((store: StoreProptypes) => store);

    // Functions
    const { t } = useTranslation();

    const fileInputRef = useRef<HTMLInputElement | null>(null);

    const [validationMessage, setValidationMessage] = useState("");
    const [formSubmitMessage, setFormSubmitMessage] = useState("");

    const [problem, setProblem] = useState<string>("");
    const [otherProblem, setOtherProblem] = useState<string>("");

    const [message, setMessage] = useState('');
    const [selectedFile, setSelectedFile] = useState<File>();

    const handleFileInput = (e: any) => {
        const target = e.target;
        const fileUploaded = target.files;

        if (fileUploaded) {
            setSelectedFile(fileUploaded[0]);
            /* let reader = new FileReader();
            reader.readAsDataURL(fileUploaded[0]);
      
            reader.onload = (e) => {
              console.log("reader.result ==>", fileUploaded[0]);
            } */
        }
    }

    const HandleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        setFormSubmitMessage('');

        if (problem === '') {
            setValidationMessage(t("Please Select a Problem."));
        }
        else if (problem === "Other" && otherProblem === "") {
            setValidationMessage(t('Please Enter the Problem'));
        } else if (message === '') {
            setValidationMessage(t("Please describe your problem"));
        }
        else if (!selectedFile) {
            setValidationMessage(t("Please upload a screenshot or video recording"));
        }
        else {
            setValidationMessage("");
            alert("form submit");
            /* ScanReportProblemAPI(firstName, lastName, email, phone, website, problem, message, selectedFile).then((response) => {
                if (response.status === 200) {
                    alert("submitted");
                    setFormSubmitMessage(t("Thank your for reporting the problem"));
                }
                else {
                    alert("not Submitted");
                }
            }) */
        }
    }

    const handleBrowse = () => {
        const input = fileInputRef.current;
        input && input.click();
    }


    return (
        <>

            {currentUserStatus && (
                <>
                    <DashboardPagesTemplate>

                        <DashboardPageTitle
                            pageTitle={`${t("Report a Problem")}`}
                            subPageTitle={`${t('page subtitle', { domainName: currentWebsite?.domain })}`}
                        />

                        <Card className="report-problem-form-wrapper">
                            <CardBody>
                                {validationMessage && (
                                    <>
                                        <Alert variant='danger'>
                                            {validationMessage}
                                        </Alert>
                                    </>
                                )}
                                {formSubmitMessage && (
                                    <>
                                        <Alert variant='success'>
                                            {formSubmitMessage}
                                        </Alert>
                                    </>
                                )}
                                <Form onSubmit={(e) => HandleSubmit(e)}>
                                    <Row>
                                        <Col lg={6}>
                                            <Form.Group controlId="problem" className="mb-3">
                                                <Form.Label>{t("Select a Problem")}</Form.Label>
                                                <Form.Select required value={problem} onChange={(e) => setProblem(e.currentTarget.value)}>
                                                    <option value="" disabled>{t("Select one from the dropdown")}</option>
                                                    {problemOptions.map(item => <option value={t(item)}>{t(item)}</option>)}
                                                </Form.Select>
                                            </Form.Group>
                                            {problem === "Other" ? <Form.Group controlId="other_problem" className="mb-3">
                                                <Form.Label>{t("Enter Other Problem")}</Form.Label>
                                                <Form.Control type="text" required placeholder={t("Enter Other Problem")} value={otherProblem} onChange={(e) => setOtherProblem(e.currentTarget.value)} />
                                            </Form.Group> : <></>}
                                            <Form.Group controlId="media" className="mb-3">

                                                <Form.Label>{t("Please Upload screenshot or video of any error or issue you may have.")}</Form.Label>
                                                <div className="custom-file-control-wrapper">
                                                    <div className="custom-file-control">
                                                        <Form.Control ref={fileInputRef} type="file" required aria-label={t("Upload Screenshot or Video")} accept="image/*,video/*" onChange={(e) => handleFileInput(e)} />
                                                        <div className="fake-input-wrapper">
                                                            <div className="file-list-wrapper">
                                                                <div className="file">
                                                                    {selectedFile?.name}
                                                                </div>
                                                            </div>
                                                            <Button variant="primary" onClick={handleBrowse}>{t("Select file")}</Button>
                                                        </div>
                                                    </div>


                                                </div>
                                                <Form.Text>{t("Supported file types: png, jpg, jpeg,gif, mp4; maximum allowed file size: 25MB.")}</Form.Text>
                                            </Form.Group>
                                            <Form.Group controlId="message" className="mb-3">
                                                <Form.Label>{t("Message")}</Form.Label>
                                                <Form.Control as="textarea" value={message} placeholder={t("We would appreciate it if you describe the problem, so we can get back to you soon with a solution.")}
                                                    aria-label={t("Message")} required onChange={(e) => setMessage(e.target.value)} rows={5} />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Button type="submit" size="lg">{t("Submit")} </Button>
                                </Form>
                            </CardBody>
                        </Card>




                    </DashboardPagesTemplate>
                </>
            )
            }


        </>
    )
}

export default ReportProblemPage;