import "../../Assets/scss/components/widget-preview-layout.scss";

import { useEffect, useState, Fragment } from "react";
import SkynetTechnoText from '../../Assets/img/widget-layout/skynettechnologies.svg'
import SkynetTechnoLogo from '../../Assets/img/widget-layout/Skynet-Technologies-Logo-2.svg'

import { Accordion, Button, Card, Col, Form, Row } from "react-bootstrap";
import { LanguagesListPropTypes, MenulistPropTypes, OtherListPropTypes } from "../../PropTypes/EnableDisableServicesPropTypes";
import { StoreProptypes } from "../../PropTypes/StoreProptypes";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { getEnableDisableMenuService } from "../../Services/EnableDisableServices";

import LogoPreview from "../../Assets/img/logo-placeholder-image.webp";


import aioaIconType01 from "../../Assets/img/widget-icon/aioa-icon-type-1.svg";
import aioaIconType02 from "../../Assets/img/widget-icon/aioa-icon-type-2.svg";
import aioaIconType03 from "../../Assets/img/widget-icon/aioa-icon-type-3.svg";
import aioaIconType04 from "../../Assets/img/widget-icon/aioa-icon-type-4.svg";
import aioaIconType05 from "../../Assets/img/widget-icon/aioa-icon-type-5.svg";
import aioaIconType06 from "../../Assets/img/widget-icon/aioa-icon-type-6.svg";
import aioaIconType07 from "../../Assets/img/widget-icon/aioa-icon-type-7.svg";
import aioaIconType08 from "../../Assets/img/widget-icon/aioa-icon-type-8.svg";
import aioaIconType09 from "../../Assets/img/widget-icon/aioa-icon-type-9.svg";
import aioaIconType10 from "../../Assets/img/widget-icon/aioa-icon-type-10.svg";
import aioaIconType11 from "../../Assets/img/widget-icon/aioa-icon-type-11.svg";
import aioaIconType12 from "../../Assets/img/widget-icon/aioa-icon-type-12.svg";
import aioaIconType13 from "../../Assets/img/widget-icon/aioa-icon-type-13.svg";
import aioaIconType14 from "../../Assets/img/widget-icon/aioa-icon-type-14.svg";
import aioaIconType15 from "../../Assets/img/widget-icon/aioa-icon-type-15.svg";
import aioaIconType16 from "../../Assets/img/widget-icon/aioa-icon-type-16.svg";
import aioaIconType17 from "../../Assets/img/widget-icon/aioa-icon-type-17.svg";
import aioaIconType18 from "../../Assets/img/widget-icon/aioa-icon-type-18.svg";
import aioaIconType19 from "../../Assets/img/widget-icon/aioa-icon-type-19.svg";
import aioaIconType20 from "../../Assets/img/widget-icon/aioa-icon-type-20.svg";
import aioaIconType21 from "../../Assets/img/widget-icon/aioa-icon-type-21.svg";
import aioaIconType22 from "../../Assets/img/widget-icon/aioa-icon-type-22.svg";
import aioaIconType23 from "../../Assets/img/widget-icon/aioa-icon-type-23.svg";
import aioaIconType24 from "../../Assets/img/widget-icon/aioa-icon-type-24.svg";
import aioaIconType25 from "../../Assets/img/widget-icon/aioa-icon-type-25.svg";
import aioaIconType26 from "../../Assets/img/widget-icon/aioa-icon-type-26.svg";
import aioaIconType27 from "../../Assets/img/widget-icon/aioa-icon-type-27.svg";
import aioaIconType28 from "../../Assets/img/widget-icon/aioa-icon-type-28.svg";
import aioaIconType29 from "../../Assets/img/widget-icon/aioa-icon-type-29.svg";

import DesktopPreview from '../../Assets/img/widget-layout/desktop-preview.svg';
import MobilePreview from '../../Assets/img/widget-layout/mobile-preview.svg';

const baseURL = `${process.env.REACT_APP_BASE_URL}`;

interface PropTypes {
    isShowLangModal?: boolean,
    isShowIconPreview?: boolean,
    changeWidgetIconType?: number,
    changeWidgetIconSize?: number,
    isChangeWidgetIconCustomSize?: boolean,
    changeWidgetCustomIconSize?: number,
    changeWidgetIconColor?: string,
    changeWidgetIconPosition?: string,
    isChangeWidgetIconCustomPosition?: boolean,
    changeWidgetIconCustomLeftPosition?: number | null,
    changeWidgetIconCustomRightPosition?: number | null,
    changeWidgetIconCustomTopPosition?: number | null,
    changeWidgetIconCustomBottomPosition?: number | null,
    changeWidgetSize?: number,
    updatedMenuList?: MenulistPropTypes[],
    updatedLanguagesList?: LanguagesListPropTypes[],
    updatedOtherList?: OtherListPropTypes[],
    updatedAccessibilityProfilesList?: OtherListPropTypes[],
}

interface otherFeatureOptionsPropTypes {
    [keyname: string]: number
}

const WidgetLayoutMain = (prop: PropTypes) => {

    const { t } = useTranslation();
    const dispatch = useDispatch();


    const { accessToken, currentWebsite, languageList, user, currentWebsitePlanStatus } = useSelector((store: StoreProptypes) => store);

    const { isShowLangModal, isShowIconPreview, changeWidgetIconType, changeWidgetIconSize, isChangeWidgetIconCustomSize, changeWidgetCustomIconSize, changeWidgetIconPosition, isChangeWidgetIconCustomPosition, changeWidgetIconCustomLeftPosition, changeWidgetIconCustomRightPosition, changeWidgetIconCustomTopPosition, changeWidgetIconCustomBottomPosition, changeWidgetSize, changeWidgetIconColor, updatedMenuList, updatedLanguagesList, updatedOtherList, updatedAccessibilityProfilesList } = prop;

    // State

    const [isWhiteLabel, setIsWhiteLabel] = useState(false);
    const [isCustomBranding, setIsCustomBranding] = useState(false);
    const [customBrandLogo, setCustomBrandLogo] = useState<string | null>(null);

    const [isMac, setIsMac] = useState(false);

    const [menuList, setMenuList] = useState<MenulistPropTypes[]>([]);
    const [languagesList, setLanguagesList] = useState<LanguagesListPropTypes[]>([]);
    const [otherList, setOtherList] = useState<OtherListPropTypes[]>([]);
    const [accessibilityProfilesList, setAccessibilityProfilesList] = useState<OtherListPropTypes[]>([]);
    const [otherListTempObj, setOtherListTempObj] = useState<otherFeatureOptionsPropTypes>();

    const [showLanguagesList, setShowLanguagesList] = useState(false);

    const [widgetOversizeToggle, setWidgetOversizeToggle] = useState(false);

    const [widgetIconType, setWidgetIconType] = useState(1);

    const [selectedWidgetIconSize, setSelectedWidgetIconSize] = useState(3);
    const [selectedWidgetIconCustomSizeToggle, setSelectedWidgetIconCustomSizeToggle] = useState(false);
    const [selectedWidgetIconCustomSize, setSelectedWidgetIconCustomSize] = useState(20);

    const [widgetColor, setWidgetColor] = useState<string>('#420083');
    const [widgetTextColor, setWidgetTextColor] = useState('#FFFFFF');

    const [widgetIconPosition, setWidgetIconPosition] = useState('bottom_right');
    const [widgetIconCustomPositionToggle, setWidgetIconCustomPositionToggle] = useState(false);

    const [isWidgetIconCustomTopBottomPosition, setIsWidgetIconCustomTopBottomPosition] = useState<number>(2);
    const [widgetIconCustomTopBottomPositionValue, setWidgetIconCustomTopBottomPositionValue] = useState<number>(0);
    const [isWidgetIconCustomLeftRightPosition, setIsWidgetIconCustomLeftRightPosition] = useState<number>(2);
    const [widgetIconCustomLeftRightPositionValue, setWidgetIconCustomLeftRightPositionValue] = useState<number>(0);


    const [isWidgetPreview, setIsWidgetPreview] = useState<boolean>(true);
    const [isWidgetIconPreview, setIsWidgetIconPreview] = useState<boolean>(false);
    const [isWidgetIconPositionPreview, setIsWidgetIconPositionPreview] = useState<boolean>(false);

    const handleWidgetPreview = (type: number) => {
        if (type === 1) {
            setIsWidgetIconPreview(true);
            setIsWidgetPreview(false);
        } else {
            setIsWidgetPreview(true);
            setIsWidgetIconPreview(false);
        }
    }

    // Get Modify Menu Obj API
    useEffect(() => {
        if (accessToken && currentWebsite) {
            getEnableDisableMenuService(accessToken, currentWebsite.id, dispatch, t, setMenuList, setLanguagesList, setOtherList, setAccessibilityProfilesList);
        }
    }, [accessToken, currentWebsite]);

    useEffect(() => {
        if (updatedMenuList && updatedMenuList.length > 0) {
            setShowLanguagesList(false);
            setMenuList(updatedMenuList);
        }
    }, [updatedMenuList]);

    useEffect(() => {
        if (updatedLanguagesList && updatedLanguagesList.length > 0) {
            setShowLanguagesList(true);
            setLanguagesList(updatedLanguagesList);
        }
    }, [updatedLanguagesList]);

    useEffect(() => {
        if (updatedOtherList && updatedOtherList.length > 0) {
            setShowLanguagesList(false);
            setOtherList(updatedOtherList);
        }
    }, [updatedOtherList]);

    useEffect(() => {
        if (updatedAccessibilityProfilesList && updatedAccessibilityProfilesList.length > 0) {
            setShowLanguagesList(false);
            setAccessibilityProfilesList(updatedAccessibilityProfilesList);
        }
    }, [updatedAccessibilityProfilesList]);

    useEffect(() => {
        const userAgent = navigator.userAgent.toLowerCase();
        if (userAgent.indexOf('mac') !== -1) {
            setIsMac(true);
        } else {
            setIsMac(false);
        }
    }, []);

    useEffect(() => {
        if (otherList) {
            setOtherListTempObj(otherList.reduce((a, feature) => ({ ...a, [feature.slug]: feature.status }), {}))
        }
    }, [otherList]);

    // Widget Icon Size
    useEffect(() => {
        if (changeWidgetIconSize) {
            setSelectedWidgetIconSize((changeWidgetIconSize === 0) ? 3 : changeWidgetIconSize);
        }
    }, [changeWidgetIconSize]);
    useEffect(() => {
        if (isChangeWidgetIconCustomSize) {
            setSelectedWidgetIconCustomSizeToggle(true);
            setSelectedWidgetIconCustomSize(changeWidgetCustomIconSize ? changeWidgetCustomIconSize : 20);
        } else {
            setSelectedWidgetIconCustomSizeToggle(false);
        }
    }, [isChangeWidgetIconCustomSize, changeWidgetCustomIconSize]);


    // Widget Icon Type
    useEffect(() => {
        if (changeWidgetIconType) {
            setWidgetIconType((changeWidgetIconType === 0) ? 1 : changeWidgetIconType);
        }
    }, [changeWidgetIconType]);

    // Widget Size
    useEffect(() => {
        if (changeWidgetSize && changeWidgetSize === 1) {
            setWidgetOversizeToggle(true);
        } else {
            setWidgetOversizeToggle(false);
        }
    }, [changeWidgetSize]);

    // Widget Color
    useEffect(() => {
        if (changeWidgetIconColor) {
            if (changeWidgetIconColor.indexOf('#')) {
                setWidgetColor(`#${changeWidgetIconColor}`);
            } else {
                setWidgetColor(changeWidgetIconColor);
            }
        } else {
            setWidgetColor('#420083');
        }
    }, [changeWidgetIconColor]);

    // Widget Position
    useEffect(() => {
        if (changeWidgetIconPosition) {
            setWidgetIconPosition(changeWidgetIconPosition);
        } else {
            setWidgetIconPosition("bottom_right");
        }
    }, [changeWidgetIconPosition]);

    useEffect(() => {
        if (isChangeWidgetIconCustomPosition) {
            setWidgetIconCustomPositionToggle(true);
            if (changeWidgetIconCustomTopPosition !== null) {
                setIsWidgetIconCustomTopBottomPosition(1);
                setWidgetIconCustomTopBottomPositionValue(changeWidgetIconCustomTopPosition ? changeWidgetIconCustomTopPosition : 0);
                if (changeWidgetIconCustomLeftPosition !== null) {
                    setIsWidgetIconCustomLeftRightPosition(1);
                    setWidgetIconCustomLeftRightPositionValue(changeWidgetIconCustomLeftPosition ? changeWidgetIconCustomLeftPosition : 0);
                    setWidgetIconPosition("top_left");
                } else if (changeWidgetIconCustomRightPosition !== null) {
                    setIsWidgetIconCustomLeftRightPosition(2);
                    setWidgetIconCustomLeftRightPositionValue(changeWidgetIconCustomRightPosition ? changeWidgetIconCustomRightPosition : 0);
                    setWidgetIconPosition("top_right")
                } else {
                    console.info("Error ==>", changeWidgetIconCustomLeftPosition, changeWidgetIconCustomRightPosition, changeWidgetIconCustomTopPosition, changeWidgetIconCustomBottomPosition);
                }
            } else if (changeWidgetIconCustomBottomPosition !== null) {
                setIsWidgetIconCustomTopBottomPosition(2);
                setWidgetIconCustomTopBottomPositionValue(changeWidgetIconCustomBottomPosition ? changeWidgetIconCustomBottomPosition : 0);
                if (changeWidgetIconCustomLeftPosition !== null) {
                    setIsWidgetIconCustomLeftRightPosition(1);
                    setWidgetIconCustomLeftRightPositionValue(changeWidgetIconCustomLeftPosition ? changeWidgetIconCustomLeftPosition : 0);
                    setWidgetIconPosition("bottom_left");
                } else if (changeWidgetIconCustomRightPosition !== null) {
                    setIsWidgetIconCustomLeftRightPosition(2);
                    setWidgetIconCustomLeftRightPositionValue(changeWidgetIconCustomRightPosition ? changeWidgetIconCustomRightPosition : 0);
                    setWidgetIconPosition("bottom_right");
                } else {
                    console.info("Error ==>", changeWidgetIconCustomLeftPosition, changeWidgetIconCustomRightPosition, changeWidgetIconCustomTopPosition, changeWidgetIconCustomBottomPosition);
                }
            } else {
                console.info("Error ==>", changeWidgetIconCustomLeftPosition, changeWidgetIconCustomRightPosition, changeWidgetIconCustomTopPosition, changeWidgetIconCustomBottomPosition);
            }
        } else {
            setWidgetIconCustomPositionToggle(false);
            setWidgetIconPosition(widgetIconPosition ? widgetIconPosition : `bottom_right`);
        }
    }, [isChangeWidgetIconCustomPosition, changeWidgetIconCustomLeftPosition, changeWidgetIconCustomRightPosition, changeWidgetIconCustomTopPosition, changeWidgetIconCustomBottomPosition])

    useEffect(() => {

        if (currentWebsite) {

            if (currentWebsite.widget_size && currentWebsite.widget_size === 1) {
                setWidgetOversizeToggle(true);
            } else {
                setWidgetOversizeToggle(false);
            }

            setWidgetIconType((currentWebsite.widget_icon_type === 0 || currentWebsite.widget_icon_type === null) ? 1 : currentWebsite.widget_icon_type);

            setSelectedWidgetIconSize((currentWebsite.widget_icon_size === 0 || currentWebsite.widget_icon_size === null) ? 3 : currentWebsite.widget_icon_size);
            if (currentWebsite.is_widget_custom_size === 1) {
                setSelectedWidgetIconCustomSizeToggle(true);
                setSelectedWidgetIconCustomSize(currentWebsite.widget_icon_size_custom);
            } else {
                setSelectedWidgetIconCustomSizeToggle(false);
            }

            setWidgetIconPosition(currentWebsite.widget_position ? currentWebsite.widget_position : "bottom_right");

            if (currentWebsite.is_widget_custom_position === 1) {

                setWidgetIconCustomPositionToggle(true);

                if (currentWebsite.widget_position_top !== null) {

                    setWidgetIconCustomTopBottomPositionValue(currentWebsite.widget_position_top);
                    setIsWidgetIconCustomTopBottomPosition(1);

                    if (currentWebsite.widget_position_left !== null) {
                        setWidgetIconCustomLeftRightPositionValue(currentWebsite.widget_position_left);
                        setIsWidgetIconCustomLeftRightPosition(1);
                        setWidgetIconPosition('top_left');
                    } else {
                        setWidgetIconCustomLeftRightPositionValue(currentWebsite.widget_position_right);
                        setIsWidgetIconCustomLeftRightPosition(2);
                        setWidgetIconPosition('top_bottom');
                    }

                } else {

                    setWidgetIconCustomTopBottomPositionValue(currentWebsite.widget_position_bottom);
                    setIsWidgetIconCustomTopBottomPosition(2);

                    if (currentWebsite.widget_position_left !== null) {
                        setWidgetIconCustomLeftRightPositionValue(currentWebsite.widget_position_left);
                        setIsWidgetIconCustomLeftRightPosition(1);
                        setWidgetIconPosition('bottom_left');
                    } else {
                        setWidgetIconCustomLeftRightPositionValue(currentWebsite.widget_position_right);
                        setIsWidgetIconCustomLeftRightPosition(2);
                        setWidgetIconPosition('bottom_right');
                    }

                }



            } else {
                setWidgetIconCustomPositionToggle(false);
            }

            if (currentWebsite.widget_color_code) {
                if (currentWebsite.widget_color_code.indexOf('#')) {
                    setWidgetColor(`#${currentWebsite.widget_color_code}`);
                } else {
                    setWidgetColor(currentWebsite.widget_color_code);
                }
            } else {
                setWidgetColor('#420083');
            }

            if (currentWebsite.white_label_enabled) {
                setIsWhiteLabel(true);
            } else {
                setIsWhiteLabel(false);
            }

            if (currentWebsite.custom_branding_enabled) {
                setIsCustomBranding(true);
            } else {
                setIsCustomBranding(false);
            }

        }

    }, [currentWebsite]);


    // set current icon value in prop function
    function hex2rgb(hex_color: string) {
        var hex_color = hex_color.replace("#", "")
            , r = parseInt(hex_color.substring(0, 2), 16)
            , g = parseInt(hex_color.substring(2, 4), 16)
            , b = parseInt(hex_color.substring(4, 6), 16);
        return r + ',' + g + ',' + b;
    }
    useEffect(() => {
        if (widgetColor !== '') {
            const custom_color_rgb = hex2rgb(widgetColor);
            const rgbArray = custom_color_rgb.split(",");
            const o = Math.round(((parseInt(rgbArray[0]) * 299) + (parseInt(rgbArray[1]) * 587) + (parseInt(rgbArray[2]) * 114)) / 1000);
            const custom_text_color = (o > 125) ? "#000000" : "#ffffff";
            setWidgetTextColor(custom_text_color);
        }
    }, [widgetColor]);
    // Color Function
    const subtractLight = (color: string, amount: number) => {
        let cc = parseInt(color, 16) - amount;
        let c = (cc < 0) ? 0 : (cc);
        const d = (c.toString(16).length > 1) ? c.toString(16) : `0${c.toString(16)}`;
        return d;
    }
    const darken = (color: string, amount: number) => {
        color = (color.indexOf("#") >= 0) ? color.substring(1, color.length) : color;
        amount = parseInt(((255 * amount) / 100).toString());
        return `#${subtractLight(color.substring(0, 2), amount)}${subtractLight(color.substring(2, 4), amount)}${subtractLight(color.substring(4, 6), amount)}`;
    }

    // Custom Branding
    useEffect(() => {
        if (user) {
            if (user.brand_logo !== '' && user.brand_logo !== null && user.brand_logo !== undefined) {
                setCustomBrandLogo(`${process.env.REACT_APP_BASE_URL}/storage/app/brand_logos/${user.brand_logo}`);
            }
        }
    }, [user])

    const langFlagImg = (slug: string) => {
        if (slug && languageList && languageList.length > 0) {
            const selectItemFlag = languageList.filter((selectLang) => selectLang.code === slug).map((selectItem) => {
                return selectItem.icon;
            })
            return selectItemFlag[0];
        }
    }

    return (
        <>
            <style>
                {`
                    :root {
                        --widget--layout-selected-color: ${widgetColor};
                        --widget--layout-selected-color-dark: ${darken(widgetColor, 10)};
                        --widget--layout-selected-text-color: ${widgetTextColor};
                        --widget--layout-selected-color-light: ${hex2rgb(widgetColor)};
                    }
                `}
            </style>
            <Card>
                <Card.Header>
                    <Card.Title className="mb-0">{t('Widget Preview')}</Card.Title>
                </Card.Header>
                <Card.Body>
                    {showLanguagesList && isShowLangModal && languagesList && (languagesList.filter((filterItem) => filterItem.status === 1).length > 0) ? (
                        <>
                            <div className={`widget-layout-wrapper`}>
                                <div className="widget-layout-inner-wrapper" style={{ maxWidth: `100%` }}>
                                    <div className="lang-wrapper">
                                        <div className="lang-popup-close-icon">
                                            <div className="widget-close-icon" onClick={() => setShowLanguagesList(false)}></div>
                                        </div>
                                        <div className="lang-title">Choose the Interface Language</div>
                                        <div className="lang-list">
                                            <Row xl={2} className="g-3">
                                                {languagesList && languagesList.length > 0 ? (
                                                    <>
                                                        {languagesList.filter((filterItem) => filterItem.status === 1).map((langItem) => {
                                                            return (
                                                                <Col key={langItem.id}>
                                                                    <div className="lang-box">
                                                                        <div className="lang-icon">
                                                                            <img src={`${baseURL}/storage/app/images/languageicon/${langFlagImg(langItem.slug)}`} alt={`${langItem.name} flag`} />
                                                                        </div>
                                                                        <div className="lang-name">{langItem.name}</div>
                                                                    </div>
                                                                </Col>
                                                            )
                                                        })}
                                                    </>
                                                ) : (
                                                    <>

                                                    </>
                                                )}
                                            </Row>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : (
                        <>
                            {isShowIconPreview && (
                                <>
                                    <div className="aioa_dashboard-preview-tab">
                                        <ul>
                                            <li><Button className={`${isWidgetPreview ? `active` : ``}`} size="sm" onClick={() => handleWidgetPreview(0)}>{t(`Interface`)}</Button></li>
                                            <li><Button className={`${isWidgetIconPreview ? `active` : ``}`} size="sm" onClick={() => handleWidgetPreview(1)}>{t(`Icon Size Position`)}</Button></li>
                                        </ul>
                                    </div>
                                </>
                            )}

                            {isWidgetPreview && (
                                <>
                                    <div className={`widget-layout-wrapper ${widgetOversizeToggle ? `oversize-widget` : ``}`}>

                                        <div className="widget-layout-inner-wrapper">
                                            <div className="widget-header-wrapper">
                                                <div className="widget-close-icon"></div>
                                                <div className="widget-title-wrapper">
                                                    <span className="widget-title">Accessibility Preferences</span>
                                                    <span className="widget-title-shortcode">({isMac ? `⌃ + ⌥ + A` : `Ctrl + Alt + A`})</span>
                                                </div>
                                                <div className={`widget-language-button ${widgetOversizeToggle ? "fullwidth-language-button" : ``}`} onClick={() => setShowLanguagesList(true)} style={{ cursor: `${isShowLangModal ? `pointer` : `inherit`}` }}>
                                                    <div className="widget-language-text">
                                                        <div className="widget-language-icon"></div>
                                                        {widgetOversizeToggle && (<div className="widget-language-name">English (USA)</div>)}
                                                    </div>
                                                    <div className="widget-language-dropdown-icon"></div>
                                                </div>
                                            </div>
                                            <div className="widget-function-wrapper">

                                                <div className={`widget-function-button-wrapper ${otherListTempObj && (otherListTempObj['reset'] === 1 || otherListTempObj['accessibility_statement'] === 1 || otherListTempObj['hide_interface'] === 1) ? `active` : ``} ${widgetOversizeToggle ? `full-btn` : ``}`}>
                                                    {/* {otherListTempObj && otherListTempObj['reset'] === 1 && (<div className="function-btn"><span className="function-btn-icon reset"></span>{widgetOversizeToggle && (<span>Reset</span>)}</div>)} */}

                                                    {((otherListTempObj && otherListTempObj['accessibility_statement'] === 1)) && (<div className="function-btn"><span className="function-btn-icon accessibility_statement"></span><span>Accessibility Statement</span></div>)}

                                                    {/* {otherListTempObj && otherListTempObj['hide_interface'] === 1 && (<div className="function-btn"><span className="function-btn-icon hide_interface"></span>{widgetOversizeToggle && (<span>Hide Interface</span>)}</div>)} */}
                                                </div>

                                                <div className="widget-function-list-wrapper">
                                                    <div className="widget-function-list-inner-wrapper">

                                                        <div className={`oversize-toggle ${otherListTempObj && otherListTempObj['oversize_widget_toggle'] === 1 ? `` : `d-none`}`}>
                                                            <div className="oversize-toggle-icon"></div>
                                                            <div className="oversize-toggle-input">
                                                                <Form.Check
                                                                    type="switch"
                                                                    id="oversize-toggle"
                                                                    label="Oversize Widget"
                                                                    checked={widgetOversizeToggle}
                                                                />
                                                            </div>
                                                        </div>

                                                        {!currentWebsitePlanStatus.isFreeWidget ? <>
                                                            <div className={`accessibility-profiles ${accessibilityProfilesList && accessibilityProfilesList.length > 0 && accessibilityProfilesList.filter((filterItem) => filterItem.status === 1).length === 0 ? `d-none` : ``}`}>
                                                                <Accordion defaultActiveKey="0">
                                                                    <Accordion.Item eventKey="1">
                                                                        <Accordion.Header>
                                                                            <span className="dropdown-icon"></span>
                                                                            <span>Accessibility Profiles</span>
                                                                        </Accordion.Header>
                                                                        <Accordion.Body>
                                                                            <div className="function-list">
                                                                                <Row className="g-2" lg={widgetOversizeToggle ? 3 : 2} xl={widgetOversizeToggle ? 3 : 2}>
                                                                                    {accessibilityProfilesList && accessibilityProfilesList.length > 0 ? (
                                                                                        <>
                                                                                            {accessibilityProfilesList.filter((filterProfileItem) => filterProfileItem.status === 1).map((profileItem) => {
                                                                                                return (
                                                                                                    <Col key={profileItem.id}>
                                                                                                        <div className={`function-box`} key={profileItem.id}>
                                                                                                            <div className={`function-box-icon ${profileItem.slug}`}></div>
                                                                                                            <div className="function-box-title">{profileItem.name}</div>
                                                                                                        </div>
                                                                                                    </Col>
                                                                                                )
                                                                                            })}
                                                                                        </>
                                                                                    ) : (
                                                                                        <>

                                                                                        </>
                                                                                    )}
                                                                                </Row>
                                                                            </div>
                                                                        </Accordion.Body>
                                                                    </Accordion.Item>
                                                                </Accordion>
                                                            </div>

                                                        </> : <></>}



                                                        <div className="function-list">
                                                            <Row className="g-2" lg={widgetOversizeToggle ? 3 : 2} xl={widgetOversizeToggle ? 3 : 2}>
                                                                {menuList && menuList.length > 0 ? (
                                                                    <>
                                                                        {menuList.filter((manuFilterItem) => manuFilterItem.status === 1).map((menuItem) => {
                                                                            return (
                                                                                <Fragment key={menuItem.id}>

                                                                                    {currentWebsitePlanStatus.isFreeWidget ? <>
                                                                                        {menuItem.is_free ? <>
                                                                                            <Col>
                                                                                                <div className={`function-box ${(menuItem.slug === 'color_blindness' || menuItem.slug === 'background_color' || menuItem.slug === 'text_color' || menuItem.slug === 'title_color' || menuItem.slug === 'font_size' || menuItem.slug === 'line_height' || menuItem.slug === 'letter_spacing' || menuItem.slug === 'content_scaling') ? `dropdown-icon` : ``} `} key={menuItem.id}>
                                                                                                    <div className={`function-box-icon ${menuItem.slug}`}></div>
                                                                                                    <div className="function-box-title">{menuItem.name}</div>
                                                                                                </div>
                                                                                            </Col>
                                                                                        </> : <></>}
                                                                                    </> : <>
                                                                                        <Col>
                                                                                            <div className={`function-box ${(menuItem.slug === 'color_blindness' || menuItem.slug === 'background_color' || menuItem.slug === 'text_color' || menuItem.slug === 'title_color' || menuItem.slug === 'font_size' || menuItem.slug === 'line_height' || menuItem.slug === 'letter_spacing' || menuItem.slug === 'content_scaling') ? `dropdown-icon` : ``} `} key={menuItem.id}>
                                                                                                <div className={`function-box-icon ${menuItem.slug}`}></div>
                                                                                                <div className="function-box-title">{menuItem.name}</div>
                                                                                            </div>
                                                                                        </Col>
                                                                                    </>}


                                                                                </Fragment>
                                                                            )
                                                                        })}
                                                                    </>
                                                                ) : (
                                                                    <>

                                                                    </>
                                                                )}
                                                            </Row>
                                                        </div>

                                                        <div className={`move-widget ${otherListTempObj && otherListTempObj['move_widget'] !== 1 ? `d-none` : ``}`}>
                                                            <Accordion defaultActiveKey="1">
                                                                <Accordion.Item eventKey="1">
                                                                    <Accordion.Header>
                                                                        <span className="dropdown-icon"></span>
                                                                        <span>Move Widget</span>
                                                                    </Accordion.Header>
                                                                    <Accordion.Body>
                                                                        <div className="widget-position-list">
                                                                            <Row xl={2} className="g-3">
                                                                                <Col>
                                                                                    <div className={`position-box`}>
                                                                                        <div className={`position-icon bottom_right`}></div>
                                                                                        <span className="position-title">Bottom Right</span>
                                                                                    </div>
                                                                                </Col>
                                                                                <Col>
                                                                                    <div className={`position-box`}>
                                                                                        <div className={`position-icon bottom_center`}></div>
                                                                                        <span className="position-title">Bottom Center</span>
                                                                                    </div>
                                                                                </Col>
                                                                                <Col>
                                                                                    <div className={`position-box`}>
                                                                                        <div className={`position-icon bottom_left`}></div>
                                                                                        <span className="position-title">Bottom Left</span>
                                                                                    </div>
                                                                                </Col>
                                                                                <Col>
                                                                                    <div className={`position-box`}>
                                                                                        <div className={`position-icon middle_left`}></div>
                                                                                        <span className="position-title">Middle Left</span>
                                                                                    </div>
                                                                                </Col>
                                                                                <Col>
                                                                                    <div className={`position-box`}>
                                                                                        <div className={`position-icon top_left`}></div>
                                                                                        <span className="position-title">Top Left</span>
                                                                                    </div>
                                                                                </Col>
                                                                                <Col>
                                                                                    <div className={`position-box`}>
                                                                                        <div className={`position-icon top_center`}></div>
                                                                                        <span className="position-title">Top Center</span>
                                                                                    </div>
                                                                                </Col>
                                                                                <Col>
                                                                                    <div className={`position-box`}>
                                                                                        <div className={`position-icon top_right`}></div>
                                                                                        <span className="position-title">Top Right</span>
                                                                                    </div>
                                                                                </Col>
                                                                                <Col>
                                                                                    <div className={`position-box`}>
                                                                                        <div className={`position-icon middle_right`}></div>
                                                                                        <span className="position-title">Middle Right</span>
                                                                                    </div>
                                                                                </Col>
                                                                            </Row>
                                                                        </div>
                                                                    </Accordion.Body>
                                                                </Accordion.Item>
                                                            </Accordion>
                                                        </div>

                                                        <div className={`widget-footer-function-button-wrapper widget-function-button-wrapper ${otherListTempObj && (otherListTempObj['reset'] === 1 || otherListTempObj['accessibility_statement'] === 1 || otherListTempObj['hide_interface'] === 1) ? `active` : ``} ${!widgetOversizeToggle ? `full-btn` : ``}`}>
                                                            <Row sm={!widgetOversizeToggle ? 1 : 2}>
                                                                <Col>
                                                                    {otherListTempObj && otherListTempObj['reset'] === 1 && (<div className="function-btn"><span className="function-btn-icon reset"></span><span>Reset</span></div>)}
                                                                </Col>
                                                                <Col>
                                                                    {otherListTempObj && otherListTempObj['hide_interface'] === 1 && (<div className="function-btn"><span className="function-btn-icon hide_interface"></span><span>Hide Interface</span></div>)}
                                                                </Col>
                                                            </Row>


                                                            {/* {((otherListTempObj && otherListTempObj['accessibility_statement'] === 1)) && (<div className="function-btn"><span className="function-btn-icon accessibility_statement"></span><span>Accessibility Statement</span></div>)} */}


                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                            {isWhiteLabel ? (
                                                <>
                                                    {isCustomBranding ? (
                                                        <>
                                                            <div className="widget-footer-wrapper">
                                                                <div className="widget-footer-text">
                                                                    <div className="widget-footer-left">
                                                                        {!isCustomBranding && (<img src={SkynetTechnoText} alt="" width={209} height={37} />)}
                                                                    </div>
                                                                    <div className="widget-footer-right">
                                                                        {isCustomBranding ? (
                                                                            <>
                                                                                <img src={customBrandLogo ? customBrandLogo : LogoPreview} alt={t("Logo preview").toString()} height={45} />
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                <img src={SkynetTechnoLogo} alt="" width={110} height={45} />
                                                                            </>
                                                                        )}

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <>

                                                        </>
                                                    )}
                                                </>
                                            ) : (
                                                <>
                                                    <div className="widget-footer-wrapper">
                                                        <div className="widget-footer-text">
                                                            <div className="widget-footer-left">
                                                                {!isCustomBranding && (<img src={SkynetTechnoText} alt="" width={209} height={37} />)}
                                                            </div>
                                                            <div className="widget-footer-right">
                                                                {isCustomBranding ? (
                                                                    <>
                                                                        <img src={customBrandLogo ? customBrandLogo : LogoPreview} alt={t("Logo preview").toString()} height={45} />
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <img src={SkynetTechnoLogo} alt="" width={110} height={45} />
                                                                    </>
                                                                )}

                                                            </div>
                                                        </div>
                                                        {isCustomBranding ? (
                                                            <>

                                                            </>
                                                        ) : (
                                                            <>
                                                                {(otherListTempObj && otherListTempObj['report_problem'] === 1) && <div className="widget-footer-problem-link">Report a Problem</div>}
                                                            </>
                                                        )}
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </>
                            )}

                            {isWidgetIconPreview && (
                                <>
                                    <div className="widget-layout-wrapper">
                                        {/* <div className="aioa_dashboard-mobile-preview-toggle">
                                            <Form.Label htmlFor="widget-icon-preview-switch" aria-hidden="true">{t(`Desktop Preview`)}</Form.Label>
                                            <Form.Check
                                                type="switch"
                                                id="widget-icon-preview-switch"
                                                onChange={() => setIsWidgetIconPositionPreview(!isWidgetIconPositionPreview)}
                                                label=""
                                                aria-label={`Widget icon Size/Position Preview for ${isWidgetIconPositionPreview ? `Mobile` : `Desktop`}`}
                                            />
                                            <Form.Label htmlFor="widget-icon-preview-switch" aria-hidden="true">{t(`Mobile Preview`)}</Form.Label>
                                        </div> */}
                                        <div className="aioa_dashboard-mobile-preview">
                                            {!isWidgetIconPositionPreview ? (
                                                <>
                                                    <img className="desktop preview-img" src={DesktopPreview} alt="" />
                                                    <div className={`aioa_dashboard-mobile-preview-container desktop-preview`}>
                                                        <div className="aioa_dashboard-mobile-preview-container-inner">
                                                            <div className={`widget-icon-box-wrap ${widgetIconPosition}`}>
                                                                <div
                                                                    className={`widget-icon-box ${widgetIconCustomPositionToggle ? `custom-widget-icon-position` : `no-custom-widget-icon-position`} ${widgetIconType === 13 ? `flat-icon` : ``} ${selectedWidgetIconSize === 1 ? `extra-small-size` : selectedWidgetIconSize === 2 ? `small-size` : selectedWidgetIconSize === 3 ? `medium-size` : selectedWidgetIconSize === 4 ? `large-size` : `extra-large-size`} ${widgetTextColor} ${widgetTextColor.toUpperCase() === '#FFFFFF' ? `dark-background-icon` : `light-background-icon`}`}
                                                                    style={{
                                                                        width: selectedWidgetIconCustomSizeToggle ? (selectedWidgetIconCustomSize * 0.70) : ``,
                                                                        height: selectedWidgetIconCustomSizeToggle ? (selectedWidgetIconCustomSize * 0.70) : ``,

                                                                        top: (widgetIconCustomPositionToggle && isWidgetIconCustomTopBottomPosition === 1) ? (widgetIconCustomTopBottomPositionValue * 0.297) : ``,
                                                                        bottom: (widgetIconCustomPositionToggle && isWidgetIconCustomTopBottomPosition === 2) ? (widgetIconCustomTopBottomPositionValue * 0.297) : ``,
                                                                        left: (widgetIconCustomPositionToggle && isWidgetIconCustomLeftRightPosition === 1) ? (widgetIconCustomLeftRightPositionValue * 0.2625) : ``,
                                                                        right: (widgetIconCustomPositionToggle && isWidgetIconCustomLeftRightPosition === 2) ? (widgetIconCustomLeftRightPositionValue * 0.2625) : ``
                                                                    }}
                                                                >
                                                                    <img
                                                                        src={widgetIconType === 1 ? aioaIconType01 : widgetIconType === 2 ? aioaIconType02 : widgetIconType === 3 ? aioaIconType03 : widgetIconType === 4 ? aioaIconType04 : widgetIconType === 5 ? aioaIconType05 : widgetIconType === 6 ? aioaIconType06 : widgetIconType === 7 ? aioaIconType07 : widgetIconType === 8 ? aioaIconType08 : widgetIconType === 9 ? aioaIconType09 : widgetIconType === 10 ? aioaIconType10 : widgetIconType === 11 ? aioaIconType11 : widgetIconType === 12 ? aioaIconType12 : widgetIconType === 13 ? aioaIconType13 : widgetIconType === 14 ? aioaIconType14 : widgetIconType === 15 ? aioaIconType15 : widgetIconType === 16 ? aioaIconType16 : widgetIconType === 17 ? aioaIconType17 : widgetIconType === 18 ? aioaIconType18 : widgetIconType === 19 ? aioaIconType19 : widgetIconType === 20 ? aioaIconType20 : widgetIconType === 21 ? aioaIconType21 : widgetIconType === 22 ? aioaIconType22 : widgetIconType === 23 ? aioaIconType23 : widgetIconType === 24 ? aioaIconType24 : widgetIconType === 25 ? aioaIconType25 : widgetIconType === 26 ? aioaIconType26 : widgetIconType === 27 ? aioaIconType27 : widgetIconType === 28 ? aioaIconType28 : widgetIconType === 29 ? aioaIconType29 : ``}
                                                                        style={{
                                                                            width: selectedWidgetIconCustomSizeToggle ? ((selectedWidgetIconCustomSize - 10) * 0.70) : ``,
                                                                            height: selectedWidgetIconCustomSizeToggle ? ((selectedWidgetIconCustomSize - 10) * 0.70) : ``
                                                                        }}
                                                                        alt=""
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            ) : (
                                                <>
                                                    <img className="mobile preview-img" src={MobilePreview} alt="" />
                                                    <div className="aioa_dashboard-mobile-preview-container mobile-preview">
                                                        <div className="aioa_dashboard-mobile-preview-container-inner">
                                                            <div className={`widget-icon-box-wrap ${widgetIconPosition}`}>
                                                                <div
                                                                    className={`widget-icon-box ${widgetIconCustomPositionToggle ? `custom-widget-icon-position` : `no-custom-widget-icon-position`} ${widgetIconType === 13 ? `flat-icon` : ``} ${selectedWidgetIconSize === 1 ? `extra-small-size` : selectedWidgetIconSize === 2 ? `small-size` : selectedWidgetIconSize === 3 ? `medium-size` : selectedWidgetIconSize === 4 ? `large-size` : `extra-large-size`} ${widgetTextColor} ${widgetTextColor.toUpperCase() === '#FFFFFF' ? `dark-background-icon` : `light-background-icon`}`}
                                                                    style={{
                                                                        width: selectedWidgetIconCustomSizeToggle ? (selectedWidgetIconCustomSize * 0.70) : ``,
                                                                        height: selectedWidgetIconCustomSizeToggle ? (selectedWidgetIconCustomSize * 0.70) : ``,

                                                                        top: (widgetIconCustomPositionToggle && isWidgetIconCustomTopBottomPosition === 1) ? (widgetIconCustomTopBottomPositionValue * 0.297) : ``,
                                                                        bottom: (widgetIconCustomPositionToggle && isWidgetIconCustomTopBottomPosition === 2) ? (widgetIconCustomTopBottomPositionValue * 0.297) : ``,
                                                                        left: (widgetIconCustomPositionToggle && isWidgetIconCustomLeftRightPosition === 1) ? (widgetIconCustomLeftRightPositionValue * 0.2625) : ``,
                                                                        right: (widgetIconCustomPositionToggle && isWidgetIconCustomLeftRightPosition === 2) ? (widgetIconCustomLeftRightPositionValue * 0.2625) : ``
                                                                    }}
                                                                >
                                                                    <img
                                                                        src={widgetIconType === 1 ? aioaIconType01 : widgetIconType === 2 ? aioaIconType02 : widgetIconType === 3 ? aioaIconType03 : widgetIconType === 4 ? aioaIconType04 : widgetIconType === 5 ? aioaIconType05 : widgetIconType === 6 ? aioaIconType06 : widgetIconType === 7 ? aioaIconType07 : widgetIconType === 8 ? aioaIconType08 : widgetIconType === 9 ? aioaIconType09 : widgetIconType === 10 ? aioaIconType10 : widgetIconType === 11 ? aioaIconType11 : widgetIconType === 12 ? aioaIconType12 : widgetIconType === 13 ? aioaIconType13 : widgetIconType === 14 ? aioaIconType14 : widgetIconType === 15 ? aioaIconType15 : widgetIconType === 16 ? aioaIconType16 : widgetIconType === 17 ? aioaIconType17 : widgetIconType === 18 ? aioaIconType18 : widgetIconType === 19 ? aioaIconType19 : widgetIconType === 20 ? aioaIconType20 : widgetIconType === 21 ? aioaIconType21 : widgetIconType === 22 ? aioaIconType22 : widgetIconType === 23 ? aioaIconType23 : widgetIconType === 24 ? aioaIconType24 : widgetIconType === 25 ? aioaIconType25 : widgetIconType === 26 ? aioaIconType26 : widgetIconType === 27 ? aioaIconType27 : widgetIconType === 28 ? aioaIconType28 : widgetIconType === 29 ? aioaIconType29 : ``}
                                                                        style={{
                                                                            width: selectedWidgetIconCustomSizeToggle ? ((selectedWidgetIconCustomSize - 10) * 0.70) : ``,
                                                                            height: selectedWidgetIconCustomSizeToggle ? ((selectedWidgetIconCustomSize - 10) * 0.70) : ``
                                                                        }}
                                                                        alt=""
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    </div>

                                </>
                            )}
                        </>
                    )}
                </Card.Body>
            </Card>

        </>
    )
}

export default WidgetLayoutMain;
